@import "../../../global";

.transactionName {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FF83A1;
  margin-left: 10px;
}

@mixin note {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
}

.transactionHeader {
  display: flex;
  justify-content: space-between;
}

.amount {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #323C47;
}

.date {
  padding-right: 10px;
  font-size: 16px;
  line-height: 24px;
  color: #A5A5A5;
}

.description {
  font-size: 16px;
  color: #A5A5A5;
}

.statisticIcon {
  font-size: 26px;
}

.tab {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #A5A5A5;
}
.fillIn, .pay, .withDraw {
  @include note;
}
.fillIn {
  color: #59CF8F;
}

.pay {
  color: #FF83A1;
}

.withDraw {
  color: $medneanMainColor;
}
