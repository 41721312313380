@import "../../../../global";

.btn {
  width: 200px;
  background: $medneanMainColor;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formItem {
  margin-bottom: 0;
}
