.noData {
  padding: 15px 0;
  font-size: 18px;
  color: #000000;
  span {
    font-size: 28px;
  }
  p {
    margin: 0;
  }
}



