@import "../../../global";

.cardTabDark {
  background-color: $medneanMainColor;
  color: #FFFFFF;
  a {
    color: #FFFFFF;
  }
}

.cardTab {
  background: #FFFFFF;
}

.cardTab {

  a {
    color: #A5A5A5;
  }

  @media (max-width: 420px){
    border-radius: 50%;
  }
}

.cardTab, .cardTabDark {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #B4B4B4;
  height: 123px;
}

.cardTabDark, .cardTab {
  padding: 10px 15px;
  border-radius: 6px;
  p {
    margin: 0;
  }
}

.navIcon {
  font-size: 24px;
}

.fillIn:hover {
  color: #59CF8F !important;
}

.payService:hover {
  color: #FF83A1 !important;
}

.withDraw:hover {
  color: $medneanMainColor !important;
}

.mobileMenuCarousel {
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  margin-top: 22px;
  @media (min-width: 992px) {
    display: none;
  }
}

.menuWhiteItem, .menuBlueItem {
  display: flex;
  margin: 0 7px;
  text-align: center;

  div {
    color: #2A2828;
    min-width: 90px;
  }
  a {
    color: #2A2828;
  }
  span {
    padding: 22px;
    border-radius: 50%;
  }

}

.menuWhiteItem {
  a {
    span {
      background: #FFFFFF;
    }
    svg {
      color: #B4B4B4;
    }
  }
}

.menuBlueItem {
  a {
    span {
      background: $medneanMainColor;
    }
    svg {
      color: #FFFFFF;
    }
  }
}
