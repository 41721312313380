@import "src/global";
.signUp {
  min-height: 100vh;
  padding: 0 30px;
  .img {
    width: 60px;
  }

  .link {
    display: block;
    margin-top: 10px;
    text-align: center;

    span {
      color: #8F8F8F;
    }
  }

   .title {
    text-align: center;
    color: $medneanMainColor;
    font-size: 45px;
  }

  .formLabel label {
    font-size: 12px;
    color: #818E9B;
  }

  .formLabel > div {
    padding-bottom: 0;
  }

  .formSelect > div {
    padding-left: 0!important;
    border-top:0!important;
    border-right: 0!important;
    border-left: 0!important;
    box-shadow: none!important;
  }


  .button {
    display: block;
    width: 100%;
    margin: 0 auto 20px auto;
    box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
  }

  @media (max-width: 420px) {
    min-height: 70vh;

    img {
      display: none;
    }
  }
}

.signIn {
  min-height: 100vh;
  padding: 0 30px;
  text-align: center;
  color: #ffffff;
  .title {
    font-size: 35px;
    color: #ffffff;
  }
  .formControl {
    width: 300px;
  }
}

.bgImg {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

@media  (max-width: 767px) {
  .flexColumnReverse {
    flex-direction: column-reverse;
  }
}


.formControl {
  min-width: 300px;
  width: 100%;
}


.alertCredentials {
  font-size: 13px;
  margin-bottom: 10px;
}

.errorCredentials {
  color: red;
}

.errorCredentialsIcon {
  margin-right: 10px;
  color: red;
  vertical-align: -0.2em;
}
