.container {
  border-radius: 6px;
  background-color: #fff;
  padding: 24px 41px 20px 44px;
}

.titleBox {
  text-align: center;
  font-weight: 500;
  color: #323C47;
  font-size: 16px;
  line-height: 24px;
}

.title {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #323C47;
}

.label {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #818E9B;
}
.input {
  width: 100%;
  border: none;
  padding-left: 0;
  border-bottom: 1px solid #D3D8DD;

  &:focus {
    box-shadow: none !important;
  }
}
