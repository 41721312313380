@import "../../../../global";
.tabList [class$=tabs-nav] {
  margin: 10px 0 0 auto;
}

.tabList [class$=tabs-nav]::before {
  border-bottom: none;
}

.btn {
  width: 200px;
  background: $medneanMainColor;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabList {
  [class$=tabs-nav] {
    [class$=tabs-nav-wrap] {
      [class$=tabs-nav-list] {
        [class$=tabs-tab] {
          background: #ffffff;
          width: 150px;
          [class$=tabs-tab-btn] {
            color: #B4B4B4;
            margin: auto;
          }
        }
        [class$=tabs-tab-active] {
          background: $medneanMainColor;
          border-radius: 4px;
          width: 150px;
          [class$=tabs-tab-btn] {
            color: #FFFFFF;
            margin: auto;
          }
        }
      }
    }
  }
}
