@import '../../../../global';

.container {
  background: #ffffff;
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.15);
  padding: 25px;
}

.label {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #63d9e5;
  margin-bottom: 8px;
}

.showAccess {
  background: #f9f9f9;
}

.saveButton {
  margin-left: 10px;
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.15);
}

.saveButton,
.showAccess,
.clearButton {
  border-radius: 10px;
  height: 45px;
  min-width: 200px;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
