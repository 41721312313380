.grayText {
  font-size: 14px;
  color: #9B9B9B;
  margin: 0;
}

.input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #D3D8DD;
  padding-left: 0;

  &:focus {
    box-shadow: none !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.titleBox {
  text-align: center;
  font-weight: 500;
  color: #323C47;
  font-size: 16px;
  line-height: 24px;
}

.title {
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  text-transform: capitalize;
  color: #323C47;
  margin-bottom: 10px;
}

.card {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  border-radius: 12px;
  width: 100%;
  padding: 15px;
}
.cardBox {
  width: 100%;
}

.bonus {
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #63D9E5;
}

.errorMsg {
  font-size: 13px;
  line-height: 19px;
  color: #D32030;
}
