.container {
  border-radius: 6px;
  background-color: #fff;
  padding: 24px 15px 20px 15px;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  text-transform: capitalize;
  color: #323C47;
}

.titleBox {
  text-align: center;
  font-weight: 500;
  color: #323C47;
  font-size: 16px;
  line-height: 24px;

  p {
    font-size: 14px;
  }
}

.input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #D3D8DD;
  padding-left: 0;

  &:focus {
    box-shadow: none !important;
  }
}

.grayText {
  font-size: 14px;
  color: #9B9B9B;
  margin: 0;
}

.formItem {
  margin: 10px 0;
}
.danger {
  color: #F76565;
}
