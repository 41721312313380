.langIcon {
  width: 20px;
  margin-left: 5px;
}

.mrgTop {
  margin-top: 20px;
}

.input {
  border-radius: 10px;
}
