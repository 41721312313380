.datePicker {
  position: absolute;
  left: 0;
  top: 0;
}

.navigation {
  margin-bottom: 10px;
}

.title {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  opacity: 0.5;
}

.btnArrow {
  margin: 0 10px 0;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
  background: #FFFFFF;
  color: #99A6B6;
}

.timeInfo {
  display: flex;
  justify-content: space-between;
  color: #2C2C2C;
}

.warning,
.danger,
.free,
.disabled,
.success {
  border-radius: 4px;
  color: #FFFFFF;
  padding: 0 5px;
  display: flex;
  justify-content: center;
}

.danger {
  background: #F76565;
}

.warning {
  background: #FF9432;
}

.disabled {
  background: #D0D0D0;
}

.success {
  background: #17CE76;
}

.free {
  color: #8F8F8F;
  background: #e3e1e1;
}



