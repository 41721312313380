$medneanMainColor: #63D9E5;

.detailBtn {
  width: 133px;
  height: 35px;
  border: 2px solid $medneanMainColor;
  color: $medneanMainColor;
  border-radius: 10px;
}

@mixin default-btn {
  width: 336px;
  height: 42px;
  background: $medneanMainColor;
  border-radius: 4px;
}

@mixin closeModalButton {
  font-size: 16px;
  width: 370px;
  height: 51px;
  background: $medneanMainColor;
  border-radius: 10px;
  margin-top: 15px;
}

.errorMsg {
  color: #f5222d;
  font-size: 12px;
}

.btnSearch {
  [class$=input-group] {
    [class$=input-affix-wrapper]:not(:last-child),
    [class$=input-affix-wrapper-focused] {
      [class$=input] {
        background: inherit;
      }
    }
  }
}
