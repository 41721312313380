@import "src/global";

.label {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #818E9B;
  margin: 0;
}

.accept, .reject, .absence, .waiting, .passed {
  font-weight: 500;
  margin: 5px 0;
  height: 45px;
  border-radius: 10px;
  border: none;
}

.accept {
  border: 2px solid #17CE76;
  color: #17CE76;
  &:hover {
    color: #ffffff;
    background: #17CE76;
    border: 2px solid #17CE76;
  }
}

.absence {
  background: #f50;
  color: #ffffff;
  &:hover {
    color: #000000;
    background: #f50;
  }
}

.passed {
  background: #17CE76;
  &:hover {
    color: #000000;
    background: #17CE76;
  }
}

.waiting {
  background: #cccccc;
  &:hover {
    color: #000000;
    background: #cccccc;
  }
}

.reject {
  border: 2px solid #D32030;
  color: #D32030;
  &:hover {
    border: 2px solid #D32030;
    background: #D32030;
    color: #ffffff;
  }
}
