.container {
  border-radius: 6px;
  background-color: #fff;
  padding: 24px 25px 20px 25px;
}

.choosePeriod {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.switchBox label {
 border-radius: 53px 0 0 53px;
}

.calendarBlock {
  display: flex;
  align-items: center;
  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #848484;
  }
   svg {
     font-size: 28px;
     margin: 0 5px;
   }
}

.statsTitle {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
}

.statAmount {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #323C47;
}

.pink {
  color: #FF83A1;
}
.green {
  color: #59CF8F;
}
.yellow {
  color: #FFC632;
}
.blue {
  color: #63D9E5;
}

.smallmargin {
  margin: 8px 0;
}
