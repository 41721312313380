@import "../../../global";
.container {
  border-radius: 6px;
  background-color: #fff;
  padding: 20px 15px;
}

.titleBox {
  text-align: center;
  font-weight: 500;
  color: #323C47;
  font-size: 16px;
  line-height: 24px;
}

.serviceCard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 167px;
  background: #FFFFFF;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  border-radius: 7px;

  &:hover {
    background: $medneanMainColor;
    color: #FFFFFF;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
}

.serviceImg {
  width: 100px;
}
