@import "../../../global";
.modal [class$="modal-footer"] {
  border: 0;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: $medneanMainColor;
}

.formItem {
  margin-bottom: 0;
}

.topSearchContainer {
  padding:  10px 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
  background: #fff;
  border-radius: 12px 20px 0 0;
}

.closeButton {
  @include closeModalButton;
}

.btnSearch {
  [class$=input-group] {
    [class$=input-affix-wrapper]:not(:last-child),
    [class$=input-affix-wrapper-focused] {
      background: #EAEAEA!important;
      border-top-left-radius: 45px!important;
      border-bottom-left-radius: 45px!important;
      [class$=input] {
        background: inherit;
      }
    }
  }
}

.btnSearch {
  [class$=input-group] {
    [class$=group-addon] {
      background: #ffffff;
      [class$=input-search-button] {
        border-radius: 0 45px 45px 0!important;
        background: #EAEAEA;
        color: #BFBFBF!important;
        border-left: none;
      }
    }
  }
}

.topSelectList {
  padding:  10px 15px;
  background: #fff;
  border-radius: 12px 20px 0 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
}

.tableContainer {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
}

.table tr th {
  background-color: #ffffff;
}

.editBtn {
  border: 2px solid $medneanMainColor;
  color: $medneanMainColor;
  width: 100%;
  border-radius: 10px;
  height: 35px;
}

.warning,
.danger,
.disabled,
.grey,
.success {
  padding: 6px;
  border-radius: 4px;
  color: #ffffff;
  text-align: center;
  font-weight: 500;
  margin: 0 auto;
}

.danger {
  background: #F76565;
}

.grey {
  background: #D0D0D0;
}

.warning {
  background: #FF9432;
}

.disabled {
  background: #D0D0D0;
}

.success {
  background: #17CE76;
}
