
.textJustify {
  text-align: justify
}

.input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #D3D8DD;
  padding-left: 0;

  &:focus {
    box-shadow: none !important;
  }
}

.label {
  font-size: 12px;
  color: #818E9B;
}
