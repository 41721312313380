.container {
  margin-top: 15px;
}

.tabTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  text-transform: uppercase;
  opacity: 0.5;
}

.btnArrow {
  height: auto;
  padding: 0 5px;
  margin: 0 5px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
  background: #FFFFFF;
  color: #99A6B6;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
  }
}

.dateContainer {
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.09);
  padding: 0 15px;
}

.events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.warning,
.danger,
.disabled,
.success {
  display: inline-block;
  margin-bottom: 2px;
  padding: 2px 5px;
  border-radius: 4px;
  color: #FFFFFF;
}

.danger {
  background: #F76565;
}

.warning {
  background: #FF9432;
}

.disabled {
  background: #D0D0D0;
}

.success {
  background: #17CE76;
}
