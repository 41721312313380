@import "src/global";

.container {
  border-radius: 6px;
  background-color: #fff;
  padding: 24px 25px 20px 25px;
}

.calcTitle {
  font-weight: 500;
  color: #323C47;
  font-size: 24px;
}

.input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #D3D8DD;
  padding-left: 0;

  &:focus {
    box-shadow: none !important;
  }
}

.textCenter {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: $medneanMainColor;
}

.detailText {

  p {
    color: #323C47;
    font-weight: 600;
    font-size: 20px;
  }

  span {
    font-size: 16px;
  }
}

