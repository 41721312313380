.container {
  border-radius: 6px;
  background-color: #fff;
  padding: 35px 0 35px 0;
}

.icon {
  font-size: 130px;
  color: #59CF8F;
}

.text {
  font-size: 24px;
  line-height: 30px;
  color: #4B4C4E;
}
.textCenter {
  text-align: center;
}
