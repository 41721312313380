@import "../../../global";
.listTop {
  text-align: center;
  margin-bottom: 20px;
}

.avatar {
  position: relative;
  img {
    border-radius: 50%;
    width: 100px;
  }
  .editIcon {
    background: $medneanMainColor;
    padding: 7px;
    font-size: 15px;
    color: #ffffff;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    cursor: pointer;
  }
}

.name {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #323C47;
}

.referalCashInfo {
  color: $medneanMainColor;
}
