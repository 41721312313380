@import "../../../../global";
.container {
  margin-top: 30px
}

.topBlock{
  background: #FFFFFF;
  padding: 15px;
  height: 240px;
  margin-bottom: 25px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.datePicker{
  width: 120px;
  color: $medneanMainColor;
}

.counter{
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    color: $medneanMainColor;
    margin: 0;
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #4C5862;
    opacity: 0.5;
  }
}

.dateBlock {
  font-size: 15px;
  letter-spacing: 0.01em;
  color: #192A3E;
}

.selectLabel {
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #6A707E;
}
.select {
  color: $medneanMainColor;
}

.formItem {
  margin-bottom: 0;
}

.queneBtnWrapper {
  padding: 15px 0;

  .quentBtn {
    width: 200px;
    background: #63D9E5;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
