.container {
  border-radius: 6px;
  background-color: #fff;
  padding: 24px 41px 20px 44px;
  text-align: center;
}

.title {
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  color: #626767;
}

.text {
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.01em;
}
