@import "../../../global";
.listTop {
  text-align: center;
}

.title {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-transform: capitalize;
  color: #323C47;
  margin-top: 31px;
}

thead[class*="ant-table-thead"] th{
  background-color: transparent;
  color: #ABABAB;
  font-size: 13px;
}

.tableItem {
  color: $medneanMainColor;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
}

.table {
  tr {
    th {
      text-align: center;
    }
    td {
      text-align: center;
      padding: 10px;
    }
  }
}

