@import "../../../global";
.navBar {
  width: 100%;
  height: 65px;
  padding-right: 10px;
  background-color: #fff;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.11);
  z-index: 9;
}

.logoWrapper {
  float: left;
  img {
    width: 55px;
  }
}

.menu {
  display: flex;
  justify-content: flex-end;
}

.language {
  margin-left: 30px;
}

.content {
  min-height: 100vh;
}

.header {
  background-color: #fff;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.11);
  z-index: 9;
  padding: 0 20px;
}
