@import "src/global";
.signUp {
  min-height: 100vh;
  padding: 0 30px;
  .img {
    width: 70px;
  }

   .title {
     font-weight: 600;
     font-size: 40px;
     line-height: 60px;
     text-align: center;
     text-transform: uppercase;
     color: $medneanMainColor;
  }

  .formLabel label {
    font-size: 12px;
    color: #818E9B;
  }

  .formLabel > div {
    padding-bottom: 0;
  }

  .formSelect > div {
    padding-left: 0!important;
    border-top:0!important;
    border-right: 0!important;
    border-left: 0!important;
    box-shadow: none!important;
  }

  .button {
    display: block;
    width: 100%;
    box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
  }

  .link {
    display: block;
    margin-top: 10px;
    text-align: center;
  }

  @media (max-width: 420px) {
    min-height: 110vh;
    img {
      display: none;
    }
  }
}

.signIn {
  min-height: 100vh;
  padding: 0 30px;
  text-align: center;
  color: #ffffff;
  .title {
    font-size: 35px;
    color: #ffffff;
  }
  .formControl {
    width: 300px;
  }
}

.bgImg {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

@media  (max-width: 767px) {
  .flexColumnReverse {
    flex-direction: column-reverse;
  }
}

.formControl {
  width: 100%;
}
