@import "../../../../global";
.container {
  border-radius: 6px;
  background-color: #fff;
  padding: 27px 10px 20px 10px;
}

.settingsCard {
  background: #FFFFFF;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.20);
  border-radius: 6px;
  text-align: center;
  max-height: 153px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    margin: 5px 0;
    color: #323C47;
    font-weight: 500;
    line-height: 21px;
  }

  span {
    color: #B7B7B7;
    font-size: 30px;
  }
  &:hover {
    background: $medneanMainColor;
    color: #FFFFFF !important;
  }
}

.editIcon {
  background: $medneanMainColor;
  border-radius: 50%;
  padding: 10px;
}
