@import "../../../../global";
.container {
  margin-top: 30px
}

.formItem {
  margin-bottom: 0;
}

.queneBtnWrapper {
  padding: 15px 0;

  .quentBtn {
    width: 200px;
    background: #63D9E5;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.controlIcon {
  font-size: 22px;
  margin: 0 3px;
}

.title {
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #63D9E5;
}
