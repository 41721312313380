@import "../../../global";

.table {
  border-radius: 6px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  background: #fff;

  tr th {
    background-color: #ffffff;
  }
}

.btn {
  width: 200px;
  background: $medneanMainColor;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noData {
  padding: 15px 0;
  font-size: 16px;
  color: #000000;
  span {
    font-size: 25px;
  }
  p {
    margin: 0;
  }
}

