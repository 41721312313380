@import "../../../global";

.sideBar {
  min-height: 100vh;
  background: linear-gradient(180deg, #5fd6e4 0%, #12a3cf 100%);
  text-align: center;

  @media (max-width: 420px) {
    background: #FFFFFF;
    position: absolute;
    z-index: 999;
  }
}

[class$=ant-layout-sider-children]  {
  .logo {
    width: 72px;
  }
}

.menuImg  {
  height: 50px !important;
}

.logo {
  margin: 16px 0;

  @media (max-width: 420px) {
    display: none;
  }
}

.menu {
  background: inherit !important;
  font-size: 16px;
  text-align: center;

  @media (max-width: 420px) {
    a {
      color: #000000 !important;
    }

    svg {
      display: none;
    }
  }
}

.menu > .exit {
  @media (max-width: 420px) {
    color: $medneanMainColor;
  }
}

.ant-menu-inline-collapsed {
  li {
    display: none;
  }
  span.anticon.ant-menu-item-icon {
    display: none;
  }
}

.downloadApp li {
  overflow: visible !important;
}
