@import '~antd/dist/antd.less';
[class*='ant-table-body'], [class*='ant-list'], [class*='scrollable'], [class*='listContainer'] {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #C6C6C6;
    border-radius: 10px;
  }
}

.ant-modal-confirm .ant-modal-body {
  padding: 10px 10px !important;
}

@primary-color: #63d9e5;
@menu-collapsed-width: unset;
@menu-icon-size: 23px;
@menu-icon-size-lg: 23px;
@menu-dark-item-active-bg: #FFFFFF;
@menu-dark-highlight-color: #63d9e5;
@menu-dark-selected-item-icon-color: #63d9e5;
@layout-header-background: #dafaff;
@layout-header-padding: 0;
@badge-color: #1FACD3;
@tabs-horizontal-margin: 0 19px 0 0;
@layout-body-background: #fbfbf;
@picker-basic-cell-disabled-bg: transparent;
@table-row-hover-bg: transparent;

@primary-color: #63d9e5;@menu-collapsed-width: unset;@menu-icon-size: 23px;@menu-icon-size-lg: 23px;@menu-dark-item-active-bg: #FFFFFF;@menu-dark-highlight-color: #63d9e5;@menu-dark-selected-item-icon-color: #63d9e5;@layout-header-background: #dafaff;@layout-header-padding: 0;@badge-color: #1FACD3;@tabs-horizontal-margin: 0 19px 0 0;@layout-body-background: #fbfbf;@picker-basic-cell-disabled-bg: transparent;@table-row-hover-bg: transparent;