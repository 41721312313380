@import "../../../../global";
.menuBtn {
  display: flex;
  justify-content: center;
  padding: 30px 0;
  .btn {
    line-height: 1;
    margin: 0 20px;
    background: #f7f7f7;
    border: 2px solid #e0e0e0;
    border-radius: 4px;
    &:hover {
      background-color: $medneanMainColor;
      border-color: $medneanMainColor;
      color: #ffffff;
    }
  }
}

.createBtn {
  width: 200px;
  background: $medneanMainColor;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
