@import "../../../../global";

.container {
  background: #FFFFFF;
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.15);
  padding: 20px;
}

.label {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #63D9E5;
  margin-bottom: 8px;
}

.showAccess {
  background: #F9F9F9;
}

.saveButton {
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.15);
}

.saveButton, .showAccess, .clearButton {
  border-radius: 10px;
  height: 45px;
  min-width: 200px;
}
