
.avatar {
  width: 100px;
  border-radius: 50%;
}

.desc {
  color: #192a3e;
  font-weight: bold;
}
