.referalLinkBtn {
  width: 343px;
  height: 42px;
  margin: 10px 0;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.table {
  tr {
    th {
      text-align: center;
    }
    td {
      padding: 10px;
      text-align: center;
    }
  }
}
