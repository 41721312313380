@import "../../../global";
.title {
  font-size: 30px;
  line-height: 30px;
  color: $medneanMainColor;
}

.titleItem {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  color: $medneanMainColor;
}

.selectItem {
  color: $medneanMainColor;
}

.tableContainer {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
  margin-bottom: 20px;
}

.tableWithError {
  box-shadow: 0 0 3px rgb(255, 77, 79);
  margin-bottom: 20px;
}

.table tr th {
  opacity: 0.5;
  color: #334D6E;
  background-color: #ffffff;
}

.btnPrimary {
  border-radius: 10px;
  margin-bottom: 20px;
  &:hover {
    background: $medneanMainColor!important;
    color: #ffffff!important;
  }
}

.btnContainer {
  margin-top: 30px
}

.container {
  position: relative;
  min-height: 200px;
  padding: 20px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  .btnContainer {
    bottom: 20px;
    right: 20px;
  }
}
