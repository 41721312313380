.title {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #323C47;
}

.icon {
  font-size: 20px;
}

.grayText {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #A5A5A5;
}

.amount {
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  color: #63D9E5;
}

.referalLinkBtn {
  width: 343px;
  height: 42px;
  margin: 10px 0;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.divider {
  margin: 5px 0;
}

.priceWithoutDiscount {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #FF83A1;
}
