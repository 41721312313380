.mt_10 {
  margin: 10px 0;
}
.personalCabinet .content {
  padding: 10px 20px;
  background: linear-gradient(0deg, #dafaff, #dafaff);

  @media (max-width: 420px) {
    padding: 15px;
  }
}

.listContainer {
  padding: 20px;
  border-radius: 6px;
  background-color: #fff;
}

.listContainerTable {
  padding: 20px;
  border-radius: 6px;
  background-color: #fff;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  color: #626767;
  margin: 0;

  @media (max-width: 420px) {
    display: none;
  }
}




