.title {
  margin-bottom: 0;
  font-size: 28px;
  color: #63D9E5;
}

.btnSearch {
  [class$=input-group] {
    [class$=input-affix-wrapper]:not(:last-child),
    [class$=input-affix-wrapper-focused] {
      border-top-left-radius: 45px!important;
      border-bottom-left-radius: 45px!important;
      [class$=input] {
        background: inherit;
      }
    }
  }
}

.btnSearch {
  [class$=input-group] {
    [class$=group-addon] {
      background: #ffffff;
      [class$=input-search-button] {
        border-radius: 0 45px 45px 0!important;
        color: #BFBFBF!important;
        border-left: none;
      }
    }
  }
}
