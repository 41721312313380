.container {
  border-radius: 6px;
  background-color: #fff;
  padding: 24px 25px 20px 25px;
}

.titleBox {
  text-align: center;
  font-weight: 500;
  color: #323C47;
  font-size: 16px;
  line-height: 24px;
}

.input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #D3D8DD !important;
  padding-left: 0;

  &:focus {
    box-shadow: none !important;
  }
  :disabled {
    background: transparent;
  }
}
