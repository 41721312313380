@import "src/global";
.langIcon {
  width: 20px;
  margin-left: 5px;
}

.space {
  margin: 15px 0;
}

.select {
  width: 100%;
}
