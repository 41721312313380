.container {
  border-radius: 6px;
  background-color: #fff;
  padding: 24px 0 20px 0;
}

.icon {
  font-size: 130px;
  color: #FF83A1;
}

.text {
  font-size: 20px;
  line-height: 30px;
  color: #4B4C4E;
}