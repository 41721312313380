@import "../../../global";
.trigger {
  cursor: pointer;
  transition: color 0.3s;
  z-index: 999;
  position: absolute;
  font-size: 20px;
  color: $medneanMainColor;
  &:hover {
    color: #1890ff;
  }
}

.navIcon {
  font-size: 25px;
}

.notificationCounter {
  font-size: 10px;
  font-weight: bold;
  .ant-badge-count {
    display: none;
  }
}

.flexColumn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  div {
    margin-left: 5px;
  }
}
