@import "../../../../global";
.tabList [class$=tabs-nav] {
  margin: 10px 0 0 auto;
}

.tabList [class$=tabs-nav]::before {
  border-bottom: none;
}

.tabList {
  [class$=tabs-nav] {
    [class$=tabs-nav-wrap] {
      [class$=tabs-nav-list] {
        [class$=tabs-tab] {
          background: #ffffff;
          width: 150px;
          [class$=tabs-tab-btn] {
            color: #B4B4B4;
            margin: auto;
          }
        }
        [class$=tabs-tab-active] {
          background: $medneanMainColor;
          border-radius: 4px;
          width: 150px;
          [class$=tabs-tab-btn] {
            color: #FFFFFF;
            margin: auto;
          }
        }
      }
    }
  }
}
