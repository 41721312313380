@import '../../../global';
.title {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-transform: capitalize;
  color: #323C47;
  margin-top: 31px;
  text-align: center;
}

.textInfo {
  color: $medneanMainColor;
  display: flex;
  align-items: flex-end;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 88%;
}

.listTop {
  color: #63D9E5;
  margin-bottom: 40px;
  text-align: center;
}

.accessBtn {
  margin: 40px 0;
  width: 370px;
  height: 45px;
}

