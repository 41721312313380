@import "../../../global";
.card {
  height: 140px;
  padding: 10px 15px;
  border-radius: 6px;
  background: #FFFFFF;
  p {
    margin: 0;
  }
}

.bonusCount {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #2B2828;
}

.cardTextDecoration {
  font-weight: 600;
  font-size: 32px;
  color: $medneanMainColor;
}

.cardTitle {
  font-weight: bold;
  color: #064147;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 15px;
}
