@import "../../../global";
.table {
  tr {
    th {
      text-align: center;
    }
    td {
      text-align: center;
      padding: 10px;
    }
  }
}

.tableItem {
  color: $medneanMainColor;
  font-size: 16px;
  text-align: center;
  padding: 10px;
}

.tableItemNoColor {
  font-size: 16px;
  text-align: center;
  padding: 10px;
}
