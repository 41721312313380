.container {
  border-radius: 6px;
  background-color: #fff;
  padding: 25px 28px 25px 28px;
}

.titleBox {
  text-align: center;
  font-weight: 500;
  color: #323C47;
  font-size: 16px;
  line-height: 24px;
}

.listItem {
  cursor: pointer;
}

