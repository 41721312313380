@import "../../../global";
.titleBox {
  text-align: center;
  font-weight: 500;
  color: #323C47;
  font-size: 16px;
  line-height: 24px;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
}

.input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #D3D8DD !important;
}

.square {
  width: 21px;
  height: 21px;
  border-radius: 3px;
  display: inline-flex;
  margin: 0 5px;
}

.flexColumn {
  display: flex;
}

.btn {
  @include default-btn;
}


