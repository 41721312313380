.listTop {
  text-align: center;
}

.title {
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  line-height: 48px;
  text-transform: capitalize;
  color: #323C47;
}

.scrollable {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.icon {
  font-size: 16px;
}

.referalLink {
  display: flex;
  width: 80%;
}

.navigation {
  margin-bottom: 20px;
}

.dangerText {
  color: #F76565;
  text-align: center;
}
