@import "src/global";
.signIn {
  height: 100vh;
  .img {
    width: 100px;
  }

  .formLabel label {
    font-size: 12px;
    color: #818E9B;
  }

  .formLabel > div {
    padding-bottom: 0;
  }

   .title {
     font-weight: 600;
     font-size: 50px;
     line-height: 75px;
     text-align: center;
     text-transform: uppercase;
     color: $medneanMainColor;
  }

  .button {
    display: block;
    width: 100%;
    margin: 0 auto 20px auto;
    box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
  }

  .link {
    display: block;
    margin-top: 10px;
    text-align: center;

    span {
      color: #8F8F8F;
    }
  }

  @media (max-width: 420px) {
    height: 70vh;

    img {
      display: none;
    }
  }
}

.signUp {
  height: 100vh;
  padding: 0 30px;
  text-align: center;
  color: #ffffff;
  .title {
    font-size: 35px;
    color: #ffffff;
  }
  .formControl {
    width: 300px;
  }
}

.bgImg {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.formControl {
  min-width: 300px;
  width: 100%;
}


.alertCredentials {
  font-size: 13px;
  margin-bottom: 10px;
}

.errorCredentials {
  color: red;
}

.errorCredentialsIcon {
  margin-right: 10px;
  color: red;
  vertical-align: -0.2em;
}
